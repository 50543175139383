.mentions--multiLine .mentions__control {
  font-size: 12pt;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 15px;
  padding-bottom: 60px;
  min-height: 120px;
}

.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 15px;
  outline: 0;
  border-radius: 4px;
}

.mentions--multiLine .mentions__input:hover {
  border-color: #000;
}

.mentions--multiLine .mentions__input:focus {
  border: 2px solid #2977ff;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #f6b4b4;
}

.mentions__mention {
  position: relative;
  background-color: rgb(87, 194, 216);
  border-radius: 100px;
}
