.container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .upcoming-vehicles-promoting-spinner {
      position: fixed;
      top: 50%;
      left: 50%;
    }
  }
  
  .list-container {
    margin-bottom: 30px;
  }
  
  .MuiList-root {
    justify-content: space-between;

    .list-item {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 12px;
      padding: 10px;
      border-radius: 8px;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      justify-content: space-between;
  
      .information-container {
        display: flex;
        gap: 30px;
        width: 100%;
  
        .vehicle-image {
          height: 60px;
        }
      }
    }
  }
  
  .promote-images-button {
    display: block;
    width: 100%;
    margin-top: 60px;
    padding: 12px 20px;
    background-color: #757575;
    color: white;
    font-weight: bold;
    text-transform: none;
    border-radius: 8px;
  }
  
  .promote-images-button:hover {
    background-color: #616161;
  }
  
  .list-item-text {
    width: 50%;
  }
  