#filter-modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

#filter-modal-title .MuiDialogTitle-root {
    flex: 1 !important
}

.filter-result {
    display: flex;
    text-align: center;
    margin: 20px auto;
    width: 100%;
    justify-content: center;
}

.filter-result b {
    color: red;
    display: inline-block;
    margin-left: 5px;
}