.upcoming-vehicles-container {
  max-width: 800px;
  margin: auto;

  .menu-section {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  
    .list-container {
      justify-content: space-between;
  
      .list-item {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 12px;
        padding: 15px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        justify-content: space-between;
    
        .info-container {
          display: flex;
          gap: 30px;
          width: 80%;
  
          .list-item-text {
            width: 50%;
          }
    
          .vehicle-image {
            height: 60px;
          }
        }
  
        .button-container {
          .edit-icon,
          .delete-icon {
            color: #757575;
            transition: color 0.3s;
          }
          
          .edit-icon:hover {
            color: #57C2D8;
          }

          .delete-icon:hover {
            color: #e53935;
          }
        }
  
      }
    }
  
    .add-item-section {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-top: 25px;
      margin-bottom: 25px;
      padding: 15px;
      border: 1px dashed #ccc;
      border-radius: 8px;
      background-color: #fafafa;
    }
  
    .file-uploader-wrapper {
      p {
        display: none;
      }
    }

    .input-field {
      margin-right: 10px;
      background-color: #fff;
  
      &.year {
        width: 100px;
      }
  
      &.model {
        width: 175px;
      }
  
      &.url {
        flex-grow: 1;
      }
    }
  
    .save-changes-button {
      width: 100%;
      padding: 12px 20px;
      font-weight: bold;
    }

    .upcoming-vehicles-uploading-spinner {
      position: fixed;
      top: 50%;
      left: 50%;
    }
  } 
}
  